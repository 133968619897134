import React,{useState} from 'react'
import './home.css'
import video1 from './home.mp4'
import video2 from './journey.mp4'

const Home = () => {
  const [c,setC]=useState(9)
  const [d,setD]=useState(9)
  const cities = [
    { 
      rank: 20, 
      city: "Amsterdam", 
      country: "Netherlands", 
      latitude: 52.3676, 
      longitude: 4.9041, 
      image: "https://images.pexels.com/photos/1790573/pexels-photo-1790573.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    { 
      rank: 19, 
      city: "Los Angeles", 
      country: "USA", 
      latitude: 34.0522, 
      longitude: -118.2437, 
      image: "https://images.pexels.com/photos/3166785/pexels-photo-3166785.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    { 
      rank: 18, 
      city: "Vienna", 
      country: "Austria", 
      latitude: 48.2082, 
      longitude: 16.3738, 
      image: "https://images.pexels.com/photos/2351425/pexels-photo-2351425.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
    },
    { 
      rank: 17, 
      city: "Berlin", 
      country: "Germany", 
      latitude: 52.5200, 
      longitude: 13.4050, 
      image: "https://images.pexels.com/photos/65567/pexels-photo-65567.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
    },
    {
      rank: 16,
      city: "Milan",
      country: "Italy",
      latitude: 45.4642,
      longitude: 9.1900,
      image: "https://images.pexels.com/photos/8243237/pexels-photo-8243237.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 15,
      city: "Prague",
      country: "Czech Republic",
      latitude: 50.0755,
      longitude: 14.4378,
      image: "https://images.pexels.com/photos/126292/pexels-photo-126292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 14,
      city: "Hong Kong",
      country: "Hong Kong",
      latitude: 22.3193,
      longitude: 114.1694,
      image: "https://images.pexels.com/photos/1757231/pexels-photo-1757231.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 13,
      city: "Sydney",
      country: "Australia",
      latitude: -33.8688,
      longitude: 151.2093,
      image: "https://images.pexels.com/photos/2193300/pexels-photo-2193300.jpeg?auto=compress&cs=tinysrgb&w=700"
    },
    {
      rank: 12,
      city: "Moscow",
      country: "Russia",
      latitude: 55.7558,
      longitude: 37.6173,
      image: "https://images.pexels.com/photos/753339/pexels-photo-753339.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 11,
      city: "Seoul",
      country: "South Korea",
      latitude: 37.5665,
      longitude: 126.9780,
      image: "https://images.pexels.com/photos/237211/pexels-photo-237211.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    { 
      rank: 10, 
      city: "Istanbul", 
      country: "Turkey", 
      latitude: 41.0082, 
      longitude: 28.9784, 
      image: "https://images.pexels.com/photos/3889704/pexels-photo-3889704.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    { 
      rank: 9, 
      city: "Barcelona", 
      country: "Spain", 
      latitude: 41.3851, 
      longitude: 2.1734, 
      image: "https://images.pexels.com/photos/1874675/pexels-photo-1874675.jpeg?auto=compress&cs=tinysrgb&w=700"
    },
    { 
      rank: 8, 
      city: "Singapore", 
      country: "Singapore", 
      latitude: 1.3521, 
      longitude: 103.8198, 
      image: "https://images.pexels.com/photos/326699/pexels-photo-326699.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    { 
      rank: 7, 
      city: "Bangkok", 
      country: "Thailand", 
      latitude: 13.7563, 
      longitude: 100.5018, 
      image: "https://images.pexels.com/photos/1031659/pexels-photo-1031659.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    {
      rank: 6,
      city: "Dubai",
      country: "Arab Emirates",
      latitude: 25.276987,
      longitude: 55.296249,
      image: "https://images.pexels.com/photos/1467300/pexels-photo-1467300.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      rank: 5,
      city: "London",
      country: "UK",
      latitude: 51.507341,
      longitude: -0.127497,
      image: "https://images.pexels.com/photos/635608/pexels-photo-635608.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 4,
      city: "Tokyo",
      country: "Japan",
      latitude: 35.680578,
      longitude: 139.768496,
      image: "https://images.pexels.com/photos/2506923/pexels-photo-2506923.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 3,
      city: "Rome",
      country: "Italy",
      latitude: 41.896562,
      longitude: 12.482170,
      image: "https://images.pexels.com/photos/2422251/pexels-photo-2422251.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 2,
      city: "Paris",
      country: "France",
      latitude: 48.857450,
      longitude: 2.351338,
      image: "https://images.pexels.com/photos/3105066/pexels-photo-3105066.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 1,
      city: "New York",
      country: "USA",
      latitude: 40.712376,
      longitude: -74.006040,
      image: "https://images.pexels.com/photos/1389339/pexels-photo-1389339.jpeg?auto=compress&cs=tinysrgb&w=800"
    }
  ];
  const attractions = [
    {
      rank: 20,
      name: "Acropolis of Athens",
      city: "Atene",
      country: "Grecia",
      latitude: 37.9715,
      longitude: 23.7257,
      image: "https://images.pexels.com/photos/15186555/pexels-photo-15186555/free-photo-of-citta-persone-punto-di-riferimento-edificio.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 19,
      name: "Empire State Building",
      city: "New York",
      country: "USA",
      latitude: 40.748817,
      longitude: -73.985428,
      image: "https://images.pexels.com/photos/2404843/pexels-photo-2404843.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 18,
      name: "Sagrada Familia",
      city: "Barcellona",
      country: "Spagna",
      latitude: 41.4036,
      longitude: 2.1744,
      image: "https://images.pexels.com/photos/819764/pexels-photo-819764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 17,
      name: "Christ the Redeemer",
      city: "Rio de Janeiro",
      country: "Brasile",
      latitude: -22.9519,
      longitude: -43.2105,
      image: "https://images.pexels.com/photos/2868240/pexels-photo-2868240.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 16,
      name: "Sydney Opera House",
      city: "Sydney",
      country: "Australia",
      latitude: -33.8568,
      longitude: 151.2153,
      image: "https://images.pexels.com/photos/1878293/pexels-photo-1878293.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 15,
      name: "Louvre Museum",
      city: "Parigi",
      country: "Francia",
      latitude: 48.8606,
      longitude: 2.3376,
      image: "https://images.pexels.com/photos/11783055/pexels-photo-11783055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 14,
      name: "Petra",
      city: "Ma'an",
      country: "Giordania",
      latitude: 30.3285,
      longitude: 35.4444,
      image: "https://images.pexels.com/photos/4388167/pexels-photo-4388167.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 13,
      name: "Grand Canyon",
      city: "Arizona",
      country: "USA",
      latitude: 36.1070,
      longitude: -112.1130,
      image: "https://images.pexels.com/photos/2379864/pexels-photo-2379864.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 12,
      name: "Mont Saint-Michel",
      city: "Normandia",
      country: "Francia",
      latitude: 48.6361,
      longitude: -1.5115,
      image: "https://images.pexels.com/photos/4245816/pexels-photo-4245816.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 11,
      name: "St. Peter's Basilica",
      city: "Città del Vaticano",
      country: "Vaticano",
      latitude: 41.9022,
      longitude: 12.4539,
      image: "https://images.pexels.com/photos/6188084/pexels-photo-6188084.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 10,
      name: "Machu Picchu",
      city: "Cusco",
      country: "Perù",
      latitude: -13.1631,
      longitude: -72.5450,
      image: "https://images.pexels.com/photos/90597/pexels-photo-90597.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 9,
      name: "Taj Mahal",
      city: "Agra",
      country: "India",
      latitude: 27.1751,
      longitude: 78.0421,
      image: "https://images.pexels.com/photos/5458393/pexels-photo-5458393.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 8,
      name: "Golden Gate Bridge",
      city: "San Francisco",
      country: "USA",
      latitude: 37.8199,
      longitude: -122.4783,
      image: "https://images.pexels.com/photos/1006964/pexels-photo-1006964.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 7,
      name: "Colosseum",
      city: "Roma",
      country: "Italia",
      latitude: 41.8902,
      longitude: 12.4922,
      image: "https://images.pexels.com/photos/2225439/pexels-photo-2225439.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 6,
      name: "Statue of Liberty",
      city: "New York",
      country: "USA",
      latitude: 40.6892,
      longitude: -74.0445,
      image: "https://images.pexels.com/photos/887848/pexels-photo-887848.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 5,
      name: "Pyramids of Giza",
      city: "Giza",
      country: "Egitto",
      latitude: 29.9792,
      longitude: 31.1342,
      image: "https://images.pexels.com/photos/3772630/pexels-photo-3772630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 4,
      name: "Forbidden City",
      city: "Pechino",
      country: "Cina",
      latitude: 39.9163,
      longitude: 116.3972,
      image: "https://images.pexels.com/photos/189833/pexels-photo-189833.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 3,
      name: "Palace of Versailles",
      city: "Versailles",
      country: "Francia",
      latitude: 48.8049,
      longitude: 2.1204,
      image: "https://images.pexels.com/photos/13692202/pexels-photo-13692202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 2,
      name: "Great Wall of China",
      city: "Pechino",
      country: "Cina",
      latitude: 40.4319,
      longitude: 116.5704,
      image: "https://images.pexels.com/photos/2304791/pexels-photo-2304791.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 1,
      name: "Yellowstone National Park",
      city: "Wyoming",
      country: "USA",
      latitude: 44.4280,
      longitude: -110.5885,
      image: "https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    }
  ];
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  const [isPlaying2, setIsPlaying2] = useState(false);

  const handlePlay2 = () => {
    setIsPlaying2(true);
  };

  const handlePause2 = () => {
    setIsPlaying2(false);
  };

  return (
    <div className='home'>
      <div className='home-title'>
        <div className='home-title-content'>
          <small>Let's use the</small>
          <h1>Best Free Online Travel Guide</h1>
          <div className='home-title-content-button'>
            <a href="/explore" id='title-content-button1'>Get Started</a>
            <a href="/install" id='title-content-button2'>Install</a>
          </div>
        </div>
      </div>
      <div className='home-content'>
        <div className='home-content-types'>
          <div className='home-content-types-item'>
            <p>Art</p>
          </div>
          <div className='home-content-types-item'>
            <p>Archaeological Sites</p>
          </div>
          <div className='home-content-types-item'>
            <p>Attractions</p>
          </div>
          <div className='home-content-types-item'>
            <p>Castles</p>
          </div>
          <div className='home-content-types-item'>
            <p>Museums</p>
          </div>
          <div className='home-content-types-item'>
            <p>Religious Places</p>
          </div>
          <div className='home-content-types-item'>
            <p>Ruins</p>
          </div>
        </div>
        <div className='home-content-subtitle'>
          <h3>Cronology</h3>
          <svg viewBox="0 0 16 16" onClick={()=>window.confirm("Do you want delete your cronology?")&&localStorage.removeItem("cronology")}>
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
          </svg>
        </div>
        <div className='home-content-popular'>
          {localStorage.getItem("cronology")?JSON.parse(localStorage.getItem("cronology")).reverse().map((item,key)=>{
            return(
              <a href={"/explore?lat="+item.lat+"&&lon="+item.lon}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+item.img+")"}}>
                  <div className='home-content-popular-item-content'>
                    <svg viewBox="0 0 16 16">
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
                    </svg>
                    <big>{' '+new Date(item.data).getDate()}/{new Date(item.data).getMonth()}/{new Date(item.data).getFullYear()} {new Date(item.data).getHours()}:{new Date(item.data).getMinutes()}</big>
                    <br />
                    <p>{item.nome}</p>
                  </div>
                </div>
              </a>
            )
          }):<img src='https://img.freepik.com/free-vector/hand-drawn-no-data-illustration_23-2150584286.jpg?t=st=1730017467~exp=1730021067~hmac=ccb128c09d6bcc8c7a01a6287b8f98c28825473040cb70acdc1ecc50d4593a30&w=740'/>}
        </div>
        {/*<div id='home-content-separator1' className='home-content-separator'><div></div><div>+150 countries</div></div>*/}
        <div className='home-content-subtitle'>
          <h3>Popular Destinations</h3>
          <small onClick={()=>setC(cities.length)}>See All</small>
        </div>
        <div className='home-content-popular'>
          {cities.slice(0,c).map(city=>{
            return(
              <a href={"/explore?lat="+city.latitude+"&&lon="+city.longitude}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+city.image+")"}}>
                  <div className='home-content-popular-item-content'>
                    <small>{city.rank}</small>
                    <svg viewBox="0 0 16 16">
                      <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                      <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                    </svg>
                    <big>{' '+city.country}</big>
                    <br />
                    <p>{city.city}</p>
                  </div>
                </div>
              </a>
            )
          })}
        </div>
        {/*<div id='home-content-separator2' className='home-content-separator'><div>100% online</div></div>*/}
        <div className='home-content-subtitle'>
          <h3>Popular sites</h3>
          <small onClick={()=>setD(attractions.length)}>See All</small>
        </div>
        <div className='home-content-popular'>
          {attractions.slice(0,d).map(attraction=>{
            return(
              <a href={"/explore?lat="+attraction.latitude+"&&lon="+attraction.longitude}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+attraction.image+")"}}>
                  <div className='home-content-popular-item-content'>
                    <small>{attraction.rank}</small>
                    <svg viewBox="0 0 16 16">
                      <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                      <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                    </svg>
                    <big>{' '+attraction.city}, {attraction.country}</big>
                    <br />
                    <p>{attraction.name}</p>
                  </div>
                </div>
              </a>
            )
          })}
        </div>
        <div className='home-content-subtitle'>
          <h3>Videos</h3>
        </div>
        <div className='home-content-video'>
          <div className='home-content-video-item'>
            {!isPlaying&&<div className='home-content-video-item-text'>
              <p>Video Presentation</p>
              <small>Becomes part of our world</small>
            </div>}
            <video id='video1' controls src={video1} onPlay={handlePlay} onPause={handlePause}></video>
          </div>
          <div className='home-content-video-item'>
            {!isPlaying2&&<div className='home-content-video-item-text'>
              <p>Video Tutorial</p>
              <small>Little tutorial to visit our big world</small>
            </div>}
            <video id='video2' controls src={video2} onPlay={handlePlay2} onPause={handlePause2}></video>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home