import React,{useState,useEffect} from 'react'
import "./why.css"
import video2 from "./GitaClip.mp4"
import img6 from "./img6.png"

const Why = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  });
  const installaWebApp=(evt)=>{
    evt.preventDefault();
    if((!promptInstall&&!isInStandaloneMode())||!supportsPWA) {
      alert("Installing Error: To add this app to your why screen, tap the 'Share' icon and select 'Add to why'")
      return;
    }
    if(isInStandaloneMode()) {
      alert("Installing Error: You had just installed it")
      return;
    }
    promptInstall.prompt();
  }
  return(
    <div className='why'>
      <div className='why-content'>
        <div className='why-content-title'>
          <h1 className='why-content-title-text'>What <big style={{color:'#68C6F5',fontSize:"1.85rem"}}>to See</big> in...? Discover <big style={{color:'#68C6F5',fontSize:"1.85rem"}}>Attractions</big> Around the World.</h1>
          <div className='why-content-button'>
            <a href="/explore">Start now!</a>
          </div>
          <small>or</small>
          <div className='why-content-button'>
            <a onClick={installaWebApp}>Install App!</a>
          </div>
        </div>
        <div className='why-content-img'>
          <img src={img6} alt="whyJourney" />
        </div>
      </div>
      <br />
      <div className='why1-title'>
        <h2>We are <big style={{color:'#D7F3F6',fontSize:"1.85rem"}}>eliminating the issues</big> of traditional tourism</h2>
      </div>
      <br />
      <div className='why1'>
        <div className='why1-stats'>
          <div className='why1-stats-item' style={{background: "linear-gradient(#FFE2C7 0%,black 150%)"}}>
            <div>
              <big>Semplicity</big>
              <br />
              <br />
              <small>100% of the experience is online, free and without the use of staff</small>
            </div>
            <div>
              <img src="https://images.pexels.com/photos/3085495/pexels-photo-3085495.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="semplicity" />
            </div>
          </div>
          <div className='why1-stats-item' style={{background: "linear-gradient(#B0ADE9  0%,black 150%)"}}>
            <div>
              <big>Lost Attractions</big>
              <br />
              <br />
              <small>Usual attractions are visited, leaving the less famous ones in the unknown</small>
            </div>
            <div>
              <img src="https://images.pexels.com/photos/21031394/pexels-photo-21031394/free-photo-of-church-of-saint-ildefonso-in-portugal.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="lost" />
            </div>
          </div>
          <div className='why1-stats-item' style={{background: "linear-gradient(#D7F3F6  0%,black 150%)"}}>
            <div>
              <big>Verified Sources</big>
              <br />
              <br />
              <small>Written texts are found from verified sources</small>
            </div>
            <div>
              <img src="https://images.pexels.com/photos/5212667/pexels-photo-5212667.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="verified" />
            </div>
          </div>
          <div className='why1-stats-item' style={{background: "linear-gradient(#68C6F5   0%,black 150%)"}}>
            <div>
              <big>Global</big>
              <br />
              <br />
              <small>We are in every country in the world in 5 different languages</small>
            </div>
            <div>
              <img src="https://images.pexels.com/photos/7235798/pexels-photo-7235798.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="global" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className='why1-videoGita'>
          <video src={video2} controls autoPlay loop muted type="video/mp4"></video>
        </div>
      </div>
      <br />
      <div className='why2-title'>
        <h2>We are <big style={{color:'#B0ADE9',fontSize:"1.85rem"}}>resetting the tourism</big></h2>
      </div>
      <br />
      <div className='why2'>
        <img src="https://images.pexels.com/photos/21316202/pexels-photo-21316202/free-photo-of-scenic-pyramids-of-giza.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="piramide" style={{marginBottom:"100px"}}/>
        <p><big style={{color:'#B0ADE9',fontSize:"1.85rem"}}>Journey</big> shifts the power to the people, where it belongs</p>
        <img src="https://images.pexels.com/photos/21328362/pexels-photo-21328362/free-photo-of-man-sitting-on-cliff-and-looking-at-lake-in-inyo-national-forest-in-california-usa.jpeg?auto=compress&cs=tinysrgb&w=600" alt="lake" style={{marginTop:"100px"}}/>
      </div>
      <br />
      <div className='why3-title'>
        <h2><big style={{color:'#FFE2C7',fontSize:"1.85rem"}}>Goers finally enjoy</big> an epic experience</h2>
      </div>
      <br />
      <div className='why3'>
        <div className='why3-item' style={{background: "linear-gradient(#FFE2C7 0%,black 150%)"}}>
          <p>Paperless for the planet</p>
        </div>
        <div className='why3-item' style={{background: "linear-gradient(#B0ADE9  0%,black 150%)"}}>
          <p>Personalized experience</p>
        </div>
        <div className='why3-item' style={{background: "linear-gradient(#D7F3F6  0%,black 150%)"}}>
          <p>Real time metrics</p>
        </div>
        <div className='why3-item' style={{background: "linear-gradient(#68C6F5   0%,black 150%)"}}>
          <p>Orientation with the map</p>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  )
}

export default Why