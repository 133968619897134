import React, { useState,useEffect } from 'react'
import "./login.css"
import axios from 'axios'
import img from "../../logo.jpg"


const Login = ({serverURL}) => {
    document.title="Login | Journey"
    const [caricamento,setCaricamento]=useState(false)
    const replaceSpaces=()=>{
        document.getElementById("nome").value=document.getElementById("nome").value.replace(/ /g, "/")
    }
    const signup=()=>{
        setCaricamento(true)
        axios.put(serverURL+"signup",{
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
            nome:document.getElementById("nome").value.replace("/"," "),
            professione:document.getElementById("professione").value,
            nascita:document.getElementById("nascita").value,
            data:new Date()
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
            setCaricamento(false)
            if(e.status===200){
                console.log(e.data);
                document.cookie="email="+e.data.email
                document.cookie="password="+e.data.password  
                window.location.href="/explore"
            }else{
                alert("Signup Error: "+e.data)
            }
          })
    }
    const login=()=>{
        setCaricamento(true)
        axios.put(serverURL+"login",{
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
            setCaricamento(false)
            if(e.status===200){
                document.cookie="email="+e.data.email
                document.cookie="password="+e.data.password
                window.location.href="/explore"
            }else{
              alert("Login Error: "+e.data)
            }
        })
    }
    useEffect(()=>{
        const timer=setTimeout(()=>{
            if(document.cookie.split(";").find(i=>i.includes("email"))&&document.cookie.split(";").find(i=>i.includes("password"))){
                window.location.href="/profile"
            }
        },1000);
        return ()=>clearTimeout(timer);
    },[]);
  return (
    <div className='login'>
        {caricamento&&<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p class="fade-text">Loading data from servers...</p><p class="fade-text">Translation of content into the selected language...</p><p class="fade-text">Preparing your personalized user experience...</p><p class="fade-text">Please wait thanks...</p></div></div></div>}
        <div className='login-title'>
            <h1>Login / Signup</h1>
        </div>
        <br />
        <div className='login-content'>
            {window.location.search.substring(1).split("=")[1]==="true"&&window.location.search.substring(1).split("=")[0]==="signup"?<form className='login-content-form' onSubmit={(e)=>{
                e.preventDefault()
                signup()
            }}>
                <div style={{width:"100px"}}>
                    <img style={{width:"100%",borderRadius:"50%"}} src={img} alt="logo" />
                </div>
                <br />
                <br />
                <div className='login-content-form-item'>
                    <input type="email" id='email' placeholder='Email...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="password" id='password' placeholder='Password...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="text" id='nome' onInput={()=>replaceSpaces()} placeholder='Name/Surname...' required/>
                </div>
                <div className='login-content-form-item'>
                    <select id="professione" required>
                        <option value="Healthcare and Assistance">Healthcare and Assistance</option>
                        <option value="Legal">Legal</option>
                        <option value="Education and Instruction">Education and Instruction</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="Engineering and Architecture">Engineering and Architecture</option>
                        <option value="Commerce and Finance">Commerce and Finance</option>
                        <option value="Art and Design">Art and Design</option>
                        <option value="Science and Research">Science and Research</option>
                        <option value="Production and Manufacturing">Production and Manufacturing</option>
                        <option value="Marketing and Comunication">Marketing and Comunication</option>
                        <option value="Student">Student</option>
                    </select>
                </div>
                <div className='login-content-form-item'>
                    <input type="date" id='nascita' required/>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <button>Send</button>
                </div>
                <br />
                <div className='login-content-form-small'>
                    <div onClick={()=>window.location.href=window.location.pathname}>Login</div>
                </div>
            </form>:<form className='login-content-form' onSubmit={(e)=>{
                e.preventDefault()
                login()
            }}>
                <div style={{width:"100px"}}>
                    <img style={{width:"100%",borderRadius:"50%"}} src={img} alt="logo" />
                </div>
                <br />
                <br />
                <div className='login-content-form-item'>
                    <input type="email" id='email' placeholder='Email...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="password" id='password' placeholder='Password...' required/>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <button>Send</button>
                </div>
                <br />
                <div className='login-content-form-small'>
                    <div onClick={()=>window.location.href=window.location.pathname+"?signup=true"}>Don't You Have an Account? Signup</div>
                </div>
            </form>}
        </div>
        <br />
        <br />
    </div>
  )
}

export default Login